import React, {useEffect, useState} from "react";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import "./OurCounsil.css";
import {useNavigate} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import {storagePath} from "../../../assets/utils/soragePath";
import getCouncilData from "../../Apis/ourCouncil";

const OurCouncil = () => {
    const [councilData, setCouncilData] = useState([])

    const fetchCouncilData = async () => {
        try {
            const response = await getCouncilData();
            if (response.status === 1) {
                setCouncilData(response.data)
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        fetchCouncilData();
    }, []);

    const Card = ({slug, src, name, title}) => {
        const navigate = useNavigate();
        const handleTitleClick = () => {
            navigate(`/our-council/${slug}`);
        };
        const imageUrl = storagePath + src;
        return (
            <div className="col-md-4 col-lg-3 col-12">
                <div className="ambass-card">
                    {
                        <img src={imageUrl} alt={`${name}'s photo`}/> ||
                        <Skeleton variant="rounded" width={306} height={387} style={{borderRadius: '30px'}}/>
                    }{
                    <div
                        className="ambass-text"
                        onClick={handleTitleClick}
                        style={{cursor: "pointer"}}
                    >
                        <p className="mt-12 text-center">
                            {name}
                        </p>
                        <p className='text-center'>{title}</p>
                    </div>
                    ||
                    <Skeleton/>
                }
                </div>
            </div>
        )
            ;
    };

    return (
        <>
            <InnerBanner pageName="Our Council"/>
            <section className="cultural section">
                <div className="container">
                    {
                        councilData.map((item1, index) => (
                            <div>
                                <h2 className="text-center mb-5 mt-5 titleFont title">{item1.title}</h2>
                                <div className="row g-4 mb-4">
                                    {item1.list.map((item) => (
                                        <Card
                                            slug={item.slug}
                                            src={item.imageurl}
                                            name={item.firstname + ' ' + item.lastname}
                                            title={item.desgination}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
        </>
    );
};

export default OurCouncil;