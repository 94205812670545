import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import './Founder.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import FounderImage from '../../../assets/images/founder.png'

const Founder = () => {
    return (<div>
        <InnerBanner pageName={"Founder's Message"}/>

        <section className="founder section">
            <div className="container">
                <div className="founder-main">
                    <div className="picture me-3">
                        <img src={FounderImage} />
                        <div className="founder-info">
                            <h3 className="mb-0 text-center">Bijender Goel</h3>
                            <p className="founder-info text-center">Founder,
                                Modern Pythian Games <br/>
                            </p>
                        </div>
                    </div>

                    <div className="founder-text text-justify">
                        <p><b>Dear Participants, Supporters, and Friends,</b></p>
                        <p>The ancient Pythian Games from Greece inspired us to establish Cultural Games
                            worldwide, akin to
                            the Olympic Games. Our primary goal is to promote unity and peace among nations,
                            societies, and
                            individuals through art, culture, and traditional games via both online and offline
                            platforms.
                        </p>
                        <p>I am happy to announce the rebirth of the ancient Pythian Games as the Modern Pythian
                            Games,
                            with the aim of becoming the only cultural games event worldwide. There is no other
                            platform
                            available in the world where artists from different art categories and athletes from
                            martial
                            arts and
                            traditional sports can compete in various art, cultural, and traditional sports
                            competitions
                            like those
                            in the Olympic Games. It is a moment of great pride for India, as this initiative began
                            with the
                            revival
                            of the first Pythian Games festival at Thyagraj Stadium, New Delhi, after a hiatus of
                            1630
                            years.</p>
                        <p>This website and online portal serve as your guide and gateway to these games, explaining
                            their
                            structure and the activities involved. Everyone can register and participate in any
                            event and
                            competition globally, according to their interests. Participants will have the
                            opportunity to
                            showcase
                            their talents on a global scale. We have planned various competitions and programs for
                            students,
                            educational institutions, and corporates. We call upon governments, corporates and
                            various
                            institutions to support artists and events from various arts, as well as athletes and
                            events
                            from
                            traditional games, through corporate social responsibility and other endeavours, which
                            will help
                            boost their global image and presence. Additionally, we have taken initiatives to
                            establish the
                            Women&#39;s Kitty Clubhouse and Art Cafes program to empower women and artists, global
                            accreditation programs for artists and players, and various global awards in the fields
                            of
                            Peace, Arts,
                            and Sports. We have also taken the initiative to launch the first digital news channel
                            dedicated
                            to
                            arts, culture, and sports.</p>
                        <p> The Pythian Games will be known for preserving a pot of talent, creativity, and heritage
                            from
                            around
                            the world. Our aim is not just to host events but also to give exposure to artists and
                            athletes
                            on a
                            global scale. The Modern Pythian Games will play a crucial role in the future by
                            formalizing the
                            arts,
                            cultural, and traditional games industry, valued at over $1000 billion, into the
                            organized
                            sector,
                            thereby boosting tourism, aiding economies, and generating global employment
                            opportunities. This
                            marks only the beginning; these games will evolve and expand over time and surpass the
                            size of
                            the
                            Olympics in the years to come. I extend an invitation to all to join the first cultural
                            movement
                            of the
                            world by registering as a member on the website and being part of the governance of the
                            modern
                            Pythian Games in this remarkable journey. Let&#39;s make history and leave behind a
                            legacy for
                            future
                            generations.</p>
                        <p>With regards.<br/>
                            <b>Bijender Goel</b><br/>
                            Date: 11<sup>th</sup> May 2024
                        </p>

                    </div>
                </div>
            </div>
        </section>
    </div>)
}
export default Founder