import {Link, useLocation} from "react-router-dom";
import getBanner from "../../Apis/bannerApi";
import {useEffect, useState} from "react";
import bannerImage from '../../../assets/images/event-banner.png';
import {storagePath} from "../../../assets/utils/soragePath";
import {Carousel} from "react-bootstrap";

const InnerBanner = ({pageName, altText}) => {
  const location = useLocation();
  const [bannerData, setBannerData] = useState([])

  const handleBanner = async () => {
    let path = location.pathname.replace(/^\//, '');
    try {
      const response = await getBanner(path);
      setBannerData(response);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    handleBanner();
  }, [location])

  return (
    <Carousel className="banner-carousel" style={{marginTop: '36px'}}>
      {
        Object.keys(bannerData).map((key, index) => (
          <Carousel.Item className='banner'>
            <img className='home-banner-image'
                 src={`${storagePath}${bannerData[key]?.imgurl}` || bannerImage}
                 alt={bannerData[key].alttext || 'Banner image'}/>
            <Carousel.Caption className="home-banner-content">
              <h1>{bannerData[key].headingtext}</h1>
              {
                bannerData[key].isbuttonvisible === 1 &&
                <Link to={bannerData[key].buttonurl}
                   className="action-button mt-2 mt-md-4"><span></span><i>{bannerData[key].buttontext}</i></Link>
              }
              <p className='text-center' style={{color: 'white'}}>{bannerData[key].headingtextdescription}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))
      }
    </Carousel>
    // <>
    //     <div className="inner-banner">
    //         <figure>
    //             <img src={bannerData.imgurl ? `${storagePath}${bannerData.imgurl}` : bannerImage}
    //                  alt={bannerData.alttext}/>
    //         </figure>
    //         <div className="breadcrumb-wrap">
    //             <nav>
    //                 <ol className="breadcrumb">
    //                     <li className="breadcrumb-item">
    //                         <Link routerLink="/home">Home</Link>
    //                     </li>
    //                     <li className="breadcrumb-item active" aria-current="page">{pageName}</li>
    //                 </ol>
    //             </nav>
    //             <h2>{altText || pageName}</h2>
    //         </div>
    //     </div>
    // </>
  )
}
export default InnerBanner