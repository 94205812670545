import axiosInstance from '../../assets/utils/api';

const sendMsg = async (fullName, email, phone, message) => {
    try {
        const response = await axiosInstance.post(
            `/api/cum`,
            {
                firstName: fullName,
                emailid: email,
                contactno: phone,
                message: message,
                sessionid: '',
                apifor: 'pgapies',
            }, {
            headers: {
                'Access-Token': 'gzc6b^rasse*#twdxfiklnf',
                'Content-Type': 'application/json'
            }
        }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default sendMsg;
