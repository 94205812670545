import axiosInstance from "../../assets/utils/api";


const getAssociates = async (path) => {
    try {
        const response = await axiosInstance.get(`/api/associate`, {
            headers: {
                "page-url": path,
                "Access-Token": "oCWr3iatewR9sTe8hAs",
                "even-id": 1
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default getAssociates;