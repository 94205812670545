import './App.css';
import Header from "./app/Components/Header/Header";
import './assets/font/Font.css'
import Home from "./app/Pages/Home/Home";
import React, {useEffect, useState} from "react";
import Footer from "./app/Components/Footer/Footer";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Founder from "./app/Pages/Founder/Founder";
import Event from "./app/Pages/Event/Event";
import VisionAndMission from "./app/Pages/VisionAndMission/VisionAndMission";
import AboutUs from "./app/Pages/AboutUs/AboutUs";
import ModernPythianGames from "./app/Pages/ModernPythianGames/ModernPythianGames";
import AimAndObjectives from "./app/Pages/AimAndObjectives/AimAndObjectives";
import History from "./app/Pages/History/History";
import Contact from "./app/Pages/Contact/Contact";
import News from "./app/Pages/News/News";
import Donation from "./app/Pages/Donation/Donation";
import {useMediaQuery, useTheme, Button, Paper, Dialog} from "@mui/material";
import NewsDetails from "./app/Pages/NewsDetails/NewsDetails";
import Membership from './app/Pages/Membership/Membership';
import OurCouncil from './app/Pages/OurCouncil/OurConsil';
import PartnerDetails from './app/Pages/PartnerDetails/PartnerDetails';
import EventCategories from "./app/Pages/EventCategories/EventCategories";
import EventSubCategories from "./app/Pages/EventSubCategories/EventSubCategories";
import tracking from "./app/Apis/trackingApi";
import EventDetails from "./app/Pages/EventDetails/EventDetails";
import MetaData from "./app/Components/MetaData";
import {HelmetProvider} from "react-helmet-async";
import PrivacyAndPolicy from "./app/Pages/PrivacyAndPolicy/PrivacyAndPolicy";
import ImpNotification from "./app/Pages/ImpNotification/ImpNotification";
import {IoMdCloseCircle} from "react-icons/io";
import {storagePath} from "./assets/utils/soragePath";
import axiosInstance from "./assets/utils/api";

function App() {

    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [loading, setLoading] = useState(false);
    const [popupData, setPopupData] = useState({});
    const [popupOpen, setPopupOpen] = useState(true);
    const [scrolled, setScrolled] = React.useState(false);
    const navigate = useNavigate()
    const [tid, setTID] = useState('');
    const handleScroll = () => {
        if (window.scrollY > 100) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }
    const fetchPopupDetails = async () => {
        try {
            const response = await axiosInstance.get('/api/VIPopUp', {
                headers: {
                    'pagename': 'Home',
                    'access-token': 'PpoPuGp2@12Module',
                    'eventid': 1
                }
            })
            setPopupData(response.data[0])
            console.log('R', response);
        } catch (e) {
            console.error(e)
        }
    }
    const handleTracking = async () => {
        try {
            const response = await tracking(document.referrer, window.location.href, window.location.pathname);
            setTID(response[0].encrstr)
        } catch (e) {
            console.error(e);
        }
    }

    const handleCookiePermission = () => {
        window.localStorage.setItem('pythiangames-cookie-permission', true);
        document.getElementById('cookie').style.display = 'none';
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        handleTracking()
    }, [location]);

    useEffect(() => {
        fetchPopupDetails();
        // document.addEventListener('load', setLoading(false));
        window.addEventListener('scroll', handleScroll);
        // if (window.localStorage.getItem('pythiangames-cookie-permission')) {
        //     document.getElementById('cookie').style.display = 'none';
        // }
    }, [])
    const handlePopupClick = () => {
        // localStorage.setItem('firstTime', 'no');
        setPopupOpen(false);
        navigate(popupData?.buttonurl)
    }

    return (
        <HelmetProvider>
            <div className="App" style={{backgroundColor: '#f9fafd'}}>
                {
                    loading ? <>Loading....</> : <>
                        <Header isScrolled={scrolled}/>
                        <div style={{marginTop: isMobile ? '100px' : ''}}>
                            <MetaData/>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/home" element={<Home/>}/>
                                <Route path="/event" element={<Event/>}/>
                                <Route path="/event/:eventpath" element={<EventDetails tid={tid}/>}/>
                                <Route path="/history" element={<History/>}/>
                                <Route path="/vision-and-mission" element={<VisionAndMission/>}/>
                                <Route path="/imp-notification" element={<ImpNotification/>}/>
                                <Route path="/about-us" element={<AboutUs/>}/>
                                <Route path="/modern-pythian-games" element={<ModernPythianGames/>}/>
                                <Route path="/aim-and-objectives" element={<AimAndObjectives/>}/>
                                <Route path="/founders-message" element={<Founder/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                                <Route path="/our-council" element={<OurCouncil/>}/>
                                <Route path="/our-council/:slug" element={<PartnerDetails/>}/>
                                <Route path="/membership" element={<Membership/>}/>
                                <Route path="/event-categories/:event" element={<EventCategories/>}/>
                                <Route path="/event-categories/:event/:page" element={<EventSubCategories/>}/>
                                <Route path="/news" element={<News/>}/>
                                <Route path="/news/details" element={<NewsDetails/>}/>
                                <Route path="/donation" element={<Donation/>}/>
                                <Route path="/privacy-and-policy" element={<PrivacyAndPolicy/>}/>
                            </Routes>
                            <div id='cookie'
                                 style={{display: window.localStorage.getItem('pythiangames-cookie-permission') ? 'none' : 'block'}}>
                                <div>
                                    <p>
                                        This website uses cookies to ensure you get the best experience on our
                                        website.&nbsp;
                                        <Link to={'/privacy-and-policy'}>Learn more</Link>
                                    </p>
                                </div>
                                <button onClick={handleCookiePermission}>Got it!</button>
                            </div>
                        </div>
                        <Dialog open={popupOpen}>
                            <Paper style={{position: 'relative'}}>
                                <IoMdCloseCircle
                                    onClick={() => setPopupOpen(false)}
                                    style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        top: '20px',
                                        right: '20px',
                                        fontSize: '20pt',
                                        color: 'gray'
                                    }}/>
                                <div style={{padding: '20px', maxWidth: '500px'}}>
                                    <h4 className='text-center'>{popupData?.headingtext}</h4>
                                    {
                                        popupData?.imgurl &&
                                        <div className='py-2'>
                                            <img width={'100%'} src={`${storagePath}${popupData?.imgurl}`}
                                                 alt={popupData?.alttext}/>
                                        </div>
                                    }
                                    <p className='m-0' style={{
                                        backgroundColor: '#ecf4ff',
                                        padding: '10px 6px'
                                    }}>{popupData?.headingtextdescription}</p>
                                    <div className='d-flex justify-content-center'>
                                        <a onClick={() => handlePopupClick()}
                                           className="action-button"
                                           style={{marginTop: '10px'}}><span></span><i>{popupData?.buttontext}</i></a>
                                    </div>
                                </div>
                            </Paper>
                        </Dialog>
                        <Footer/>
                    </>
                }
            </div>
        </HelmetProvider>
    );
}

export default App;
