import axiosInstance from '../../assets/utils/api';

const getNews = async () => {
    try {
        const response = await axiosInstance.post(
            `/api/news`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default getNews;