import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'
import {FaFacebookF, FaInstagram, FaLinkedin, FaLocationDot, FaPhone, FaXTwitter, FaYoutube} from "react-icons/fa6";
import {IoMail} from "react-icons/io5";
import FooterLogo from '../../../assets/images/footer-logo.png';
import './Footer.css'
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container grid-list ">
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-center center-div">
              <div className="footer-brand ">
                <a href="/" className="logo ">
                  <img src={FooterLogo} alt="footer-image " className="rotate"/>
                </a>
                <p className="animated_rainbow_1 text-center">Unifying Nations through Arts, cultures
                  and
                  Traditional Games</p>
                <ul className="social-list justify-content-center" type='none'>
                  <li id='facebook-icon'>
                    <a href="https://www.facebook.com/Pythiangames1/?ref=pages_you_manage">
                      <FaFacebookF/>
                    </a>
                  </li>
                  <li id='twitter-icon'>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FBijendergoel">
                      <FaXTwitter/>
                    </a>
                  </li>
                  <li id='instagram-icon'>
                    <a href="https://www.instagram.com/modernpythiangames/?igshid=YmMyMTA2M2Y%3D">
                      <FaInstagram/>
                    </a>
                  </li>
                  <li id='linkdin-icon'>
                    <a
                      href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHGg-Faq3M32gAAAYk05M_wD13kUDJOKUxLvDuCLgnuX8Z4uEmfwTtpdryScnM4IwiV5JtfrHGuPJXFFN6UfFaUmFTTsfaB3FfwqcKYrHY8cG8Tve55ONVx7NCoUAVL-BviRCA=&original_referer=https://pythiangames.org/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fbijender-goel-773b6b20%2F">
                      <FaLinkedin/>
                    </a>
                  </li>
                  <li id='youtube-icon'>
                    <a
                      href="https://www.youtube.com/channel/UCr5Hz1CEmW56sdTkgAJYG_A">
                      <FaYoutube/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-5 col-lg-3 left-div">
              <ul className="footer-list quick-links">
                <h4 className="footer-list-title">Quick Links</h4>
                <li className="mb-2 ">
                  <Link to="/home" className="footer-link ">
                    Home</Link>
                </li>
                <li className="mb-2 ">
                  <Link to="/about-us" className="footer-link ">
                    About Us</Link>
                </li>
                <li className="mb-2 ">
                  <Link to="/our-council" className="footer-link ">Our Council</Link>
                </li>
                <li className="mb-2 ">
                  <Link to="/event" className="footer-link ">Events</Link>
                </li>
                <li className="mb-2 ">
                  <Link to="/contact" className="footer-link ">Contact Us </Link>
                </li>
                <li className="mb-2 ">
                  <Link to="/donation" className="footer-link">Donation</Link>
                </li>
                <li className="mb-2 ">
                  <Link to="/privacy-and-policy" className="footer-link">Privacy and Policy</Link>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-6 col-md-7 col-lg-3 right-div">
              <div className="footer-list footr-contact-info">
                <h4 className=" footer-list-title contact-title">Contact Information</h4>
                <li className="contact-div">
                  <FaLocationDot className="contact-icon" style={{width: '20px'}}/>
                  <div>
                    <span
                      style={{fontWeight: '600'}}>International Pythian Council<br/>Delphic India Trust <br/>
                    </span>3310-B/2, Old Pusa Gate,<br/>Ranjit Nagar, South Patel Nagar, New Delhi-110008,
                    India
                  </div>
                </li>
                <li className="contact-div">
                  <IoMail className="contact-icon"/>
                  <a href="mailto:info@pythiangames.org" className="footer-link ">
                    <p style={{fontSize: '16px', margin: 0}}>info@pythiangames.org</p>
                  </a>
                </li>
                <li className="contact-div">
                  <FaPhone className="contact-icon"/>
                  <a href="tel:+91 11 45520883" className="footer-link ">
                    +91 11 45520883<br/>+91 8447225005</a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy-right">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className="small mb-3 mb-md-0 text-center">
              <span className="footer-text">
                Copyright © 2024{" "}
                <span className="text-primary">
                  <a href="#" className="footer-link" previewlistener="true">
                    International Pythian Council.
                  </a>
                </span>{" "}
                All rights reserved.
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Footer