import React, { useEffect, useState } from "react";
import "./Membership.css";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import getMembership from '../../Apis/membershipApi';
import { useLocation } from "react-router-dom";
const Membership = () => {
    const [country, setCountry] = useState('india');
    const [data, setData] = useState([]);
    const paysubmit = (event) => {
        event.preventDefault();
    };

    const fetchData = async () => {
        try {
            const response = await getMembership(country == 'india' ? 0 : 1);
            setData(response);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchData();
    }, [country])

    function formatDescription(description) {
        return description.replace(/\/-/g, "/-<br>");
    }

    return (
        <>
            <InnerBanner pageName="Membership" />

            <section className="section">
                <div className="container subscription-container">
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="subscription-header">
                                <h2>Membership</h2>
                                <div className="toggle-switch">
                                    <button
                                        className={`toggle-button ${country == 'india' ? "active" : ""}`}
                                        onClick={() => setCountry("india")}
                                    >
                                        India
                                    </button>
                                    <button
                                        className={`toggle-button ${country == 'international' ? "active" : ""}`}
                                        onClick={() => setCountry("international")}
                                    >
                                        International
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
                    {
                        india ? */}
                    < div className="subscription-plans row row-no-gutters">
                        {!data.length == 0 ? (
                            data.map((plan, index) => (
                                < div className="col-12 col-md-6 col-lg-4 mb-4" key={index} >
                                    <div className="plan">
                                        <h3 className="title-1">{plan.title}</h3>
                                        <p className="main-price">
                                            <strong>{plan.amount == 0 ? ('No fee') : (`${plan.currencysign}${plan.amount}`)}</strong>
                                        </p>
                                        {plan.description && (
                                            <p className="list-group text-center memberDes" dangerouslySetInnerHTML={{
                                                __html:
                                                    formatDescription(plan.description)
                                            }}></p>
                                        )}
                                        <button
                                            className="action-button mt-2 mt-md-4 mb-4"
                                            onClick={() => window.location.href = plan.buttonurl}>
                                            <span></span>
                                            <i>{plan.buttontext}</i>
                                        </button>
                                    </div>
                                </div>
                            )
                            )) : 'No Data Found'
                        }
                    </div>
                </div>
            </section >
        </>
    );
};

export default Membership;