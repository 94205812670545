import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'

const PrivacyAndPolicy = () => {
  return (
    <>
      <InnerBanner/>
      <section className='section'>
        <div className="container w-70">
          <h2 className="mb-4 lh-base titleFont title">Cookies and Privacy Policy</h2>
          <p>
            We respect your privacy and use cookies to improve your experience. Cookies help us remember preferences,
            analyze site usage, and provide event updates. Manage cookies via your browser settings.<br/>
            <br/>
            Data Collection
            <br/>
            We collect:<br/>
            <br/>
            Personal Data: For registrations and purchases (e.g., name, contact).
            <br/>
            Non-Personal Data: For analytics (e.g., IP address).
            <br/>
            Usage
            <br/>
            Your data helps us process registrations, improve services, and meet legal requirements.<br/>
            <br/>
            Sharing and Security
            <br/>
            We only share data with trusted partners or when required by law. Your data is secure, but no system is
            foolproof.<br/>
            <br/>
            Your Rights
            <br/>
            You can access, update, or delete your data and opt out of marketing.<br/>
            <br/>

            <span style={{fontWeight: '500'}}>
              For inquiries, email [<a style={{color: 'dodgerblue'}}
                                       href='mailto:info@pythiangames.com'>info@pythiangames.com</a>]
            </span>
          </p>
        </div>
      </section>
    </>
  )
}
export default PrivacyAndPolicy