import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'

const AimAndObjectives = () => {
    return (<>
        <InnerBanner pageName='Aim and Objectives'/>
        <section className="our-history section">
            <div className="container w-70">
                <div className="row">
                    <div className="col-md-12 col-12 align-content-center ">
                        <h2 className="mb-3 lh-base titleFont title text-center">Aim and Objectives</h2>
                        <ul className="custom-list  text-justify">
                            <li>The Council serves as the global authority for organizing and conducting the modern
                                Pythian Games. It sublicenses International, national, and state entities to conduct
                                various events related to the Modern Pythian Games, including MODERN PYTHIAN GAMES,
                                REGIONAL PYTHIAN GAMES, PARA PYTHIAN GAMES, JUNIOR PYTHIAN GAMES, NATIONAL PYTHIAN
                                GAMES, JUNIOR NATIONAL PYTHIAN GAMES, All PYTHIAN AWARDS, and all Arts categories
                                and their sub-genres, mediums, formats, and modes.
                            </li>
                            <li>The Council operates globally, inviting and appointing all countries to join the
                                Pythian Movement as members.
                            </li>
                            <li>
                                This Council is the sole authority for creating, managing, and governing the
                                International Pythian Council or any other international organizations promoting
                                modern Pythian Games.
                            </li>
                            <li>The council aims to rejuvenate, promote, and nurture art forms, craft forms, and
                                various cultures, traditional games and sports to foster peace through
                                transdisciplinary and intercultural engagements.
                            </li>
                            <li>The council operates as a voluntary, not-for-profit, and public benefit entity,
                                being non-political, non-religious, and non-sectarian.
                            </li>
                            <li>The council’s benefits are accessible to everyone, regardless of caste, creed,
                                religion, sex, demographics, etc.
                            </li>
                            <li>The Council promotes the harmonious spirit of ancient civilizations, cultures,
                                various arts, and craft forms, traditional games and sports to foster peaceful
                                co-existence among races and cultures.
                            </li>
                            <li>The council encourages interest, memberships, and participation from diverse age and
                                interest groups, private and public entities, institutions, government bodies, and
                                conservationists.
                            </li>
                            <li>The Council addresses initiatives and activities based on the eight (8) Modern
                                Pythian Arts categories, encompassing various art forms, languages, traditions, and
                                innovations.
                            </li>
                            <li>It also addresses emerging forms and formats of arts, crafts, traditional games,
                                sports and communications.
                            </li>
                            <li>The Council upholds the values that arts bring about, encouraging co-creation,
                                co-learning, and tolerance among individuals and communities.
                            </li>
                            <li>The council acts as a catalyst in identifying alternate avenues of employment for
                                artists and players, fostering a thriving creative and cultural economy in
                                collaboration with various stakeholders.
                            </li>
                            <li>The Council encourages research, development, and policy formulation to rejuvenate
                                arts and cultures, traditional games and various sports with results benefiting the
                                public interest.
                            </li>
                            <li>The council institutes awards, scholarships, and honours to recognize excellence in
                                the Pythian Games and Pythian Movement Ecosystem.
                            </li>
                            <li>The Council conducts programs, workshops, and events that engage stakeholders in
                                expressive, artistic, and sports endeavours.
                            </li>
                            <li>The council raises funds through grants, sponsorships, memberships, and
                                merchandising to support its objectives, ensuring transparency and accountability.
                            </li>
                            <li>The Council promotes intellectual properties and cultural concepts to further its
                                objectives.
                            </li>
                            <li>The council sublicenses independent Pythian Clubs and Artisan Clubs to promote
                                various art categories and support artists.
                            </li>
                            <li>The Council garners media support to disseminate information about the modern
                                Pythian Games.
                            </li>
                            <li>The council promotes activities aligned with its objectives, consulting with
                                advisory boards and stakeholders.
                            </li>
                            <li>The council shall utilize revenue generated solely for the purposes of the Council,
                                in compliance with relevant regulations.
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </section>

    </>)
}
export default AimAndObjectives