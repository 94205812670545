import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'
import './News.css'
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import getNews from "../../Apis/newsApi";
import 'https://static.elfsight.com/platform/platform.js'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


const News = () => {
    const navigate = useNavigate();

    const [newsData, setNewsData] = useState([]);

    const fetchNews = async () => {
        try {
            const response = await getNews();
            setNewsData(response.data)
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    useEffect(() => {
        fetchNews()
        const script = document.createElement('script');
        script.src = "https://snapwidget.com/js/snapwidget.js";
        script.async = true;
        document.body.appendChild(script);
    }, [])

    const card = (src, text, currentContent) => {
        return (
            <div className="col-sm-6 col-md-4 mb-3">
                <div className="news-wrap">
                    <div className="singleNews">
                        <a onClick={() => navigate('details', {
                            state: {
                                index: currentContent
                            }
                        })}>
                            <figure>
                                <img src={src} className="w-100"/>
                            </figure>
                        </a>
                        <figcaption>
                            <h3 className="text2line">{text}
                            </h3>
                            <span onClick={() => navigate('details', {
                                state: {
                                    index: currentContent
                                }
                            })} style={{cursor: 'pointer'}} className="activeLink themeColor">
                                Read More <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 10.5H20.25M20.25 10.5L15 15.75M20.25 10.5L15 5.25" stroke="#0FA958"
                                      stroke-width="1.29" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </span>
                        </figcaption>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <InnerBanner pageName='News' altText='Founder Desk'/>
            <section className="about section" style={{animationDelay: "200ms"}}>
                <div className="container">
                    <h2 className="mb-4 lh-base titleFont text-center title">Instagram</h2>
                    {/*<div className="elfsight-app-bd210afb-4144-4a9a-a93a-eacbc3e6b935" data-elfsight-app-lazy></div>*/}
                        <iframe
                            src="https://snapwidget.com/embed/1082162"
                            className="snapwidget-widget"
                            allowTransparency="true"
                            frameBorder="0"
                            scrolling="no"
                            style={{border: 'none', overflow: 'hidden', width: '100%'}}
                            title="Posts from Instagram"
                        />
                    <h2 className="mb-4 lh-base titleFont text-center title">Linkedin</h2>
                    <div className="elfsight-app-159643d4-12c6-45b8-b64e-cc3601b580d8" data-elfsight-app-lazy></div>
                    <h2 className="mb-4 lh-base titleFont text-center title"> Latest News</h2>
                    <div className="row">
                        {
                            newsData.map((item, index) => (
                                card(`${process.env.REACT_APP_API_URL}/${item.file}`, item.title, index)
                            ))
                        }
                    </div>
                </div>
            </section>

        </>
    )
}
export default News