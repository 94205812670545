import axiosInstance from '../../assets/utils/api';

const eventsApi = async (eventType) => {
    try {
        const response = await axiosInstance.get(`api/events`,
            {
                headers: {
                    'access-token': 'Ventthof&$EYuYeCr3*PrvROz_9roUp', 'eventtype': eventType
                }
            });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default eventsApi;