import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
)

export default axiosInstance;