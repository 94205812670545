import 'bootstrap/dist/css/bootstrap.min.css';
import './NewsDetails.css'

import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import getNews from '../../Apis/newsApi';
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


const NewsDetails = () => {
    const location = useLocation();
    const {state} = location;

    const [newsData, setNewsData] = useState([]);
    const [page, setpage] = useState(0);
    const [data, setData] = useState({title: '', des: '', imageSrc: ''});
    const fetchNews = async () => {
        try {
            const response = await getNews();
            setNewsData(response.data)
            setData({
                title: response.data[state.index].title,
                des: response.data[state.index].description,
                imageSrc: `${process.env.REACT_APP_API_URL}/${response.data[state.index].file}`,
            })
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    useEffect(() => {
        fetchNews()
        setpage(state.index);
    }, [])

    return (<>
        <InnerBanner pageName='News'/>
        <section className="about section">
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div className="col-lg-12">
                            <figure>
                                <img className="w-100" src={data.imageSrc}/>
                            </figure>
                            <h2 className="news-heading">{data.title}</h2>
                            <p style={{textAlign: 'initial!important'}}>{data.des === null ? '' : data.des}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="sidebar-wrap">

                            <h3 className="mb-4">Latest News</h3>
                            <div className="news-wrap">
                                {newsData.map((item, index) => (<div className="singleNews-wrap">
                                    <a className="singleNews" onClick={() => setData({
                                        title: item.title,
                                        des: item.description === "null" || item.description === null ? "" : item.description,
                                        imageSrc: `${process.env.REACT_APP_API_URL}/${item.file}`,
                                    })}>
                                        <figure>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/${item.file}`}/>
                                        </figure>
                                        <figcaption>
                                            <span>{item.title}</span>
                                        </figcaption>
                                    </a>
                                </div>))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}
export default NewsDetails