import axiosInstance from '../../assets/utils/api';

const contactDetails = async () => {
    try {
        const response = await axiosInstance.get(
            `/api/ContactUs`, {
            headers: {
                'Access-Token': 'DkEhLuVsEkSutcatNOC',
                'pageurl':'contactus'
            }
        }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default contactDetails;