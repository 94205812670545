import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css'
import {useEffect, useState} from "react";
import sendMsg from "../../Apis/contactApi";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import contactDetails from "../../Apis/contactDetails";
import {storagePath} from "../../../assets/utils/soragePath";

const Contact = () => {
    const [formData, setFormData] = useState({
        fullName: '', email: '', phone: '', message: ''
    })
    const [isDisable, setIsDesable] = useState(false);
    const [contactDetail, setContactDetail] = useState([]);
    const path = storagePath;
    const handleContactDetails = async () => {
        try {
            const response = await contactDetails();
            setContactDetail(response)
        } catch (e) {
            console.error(e)
        } finally {
            setIsDesable(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDesable(true);
        try {
            const response = await sendMsg(formData.fullName, formData.email, formData.phone, formData.message);
            if (response.status == 1) {
                Toastify({
                    text: 'Message sent',
                    className: "info",
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    }
                }).showToast();
            }

        } catch (e) {
            console.error(e)
            Toastify({
                text: 'Failed to send Message',
                className: "info",
                style: {
                    background: "linear-gradient(to right, #ff0000, #6c1919)",
                }
            }).showToast();
        } finally {
            setIsDesable(false)
        }
    }

    const navLink = (type, value) => {
        if (type === 'email') {
            return `mailto:${value}`
        } else if (type === 'mobile') {
            let cleanedNumber = value.replace("+91 ", "");
            return `tel:${cleanedNumber}`
        } else if (type === 'whatsapp') {
            let cleanedNumber = value.replace("+91 ", "");
            return `https://api.whatsapp.com/send?phone=${cleanedNumber}`
        }
    }

    const handleChange = (e) => {
        const inputValue = e
        const numericValue = inputValue.replace(/\D/g, '');
        setFormData({...formData, phone: numericValue});
    };

    useEffect(() => {
        handleContactDetails()
        handleChange(formData.phone);
    }, [formData.phone]);
    return (<>
        <InnerBanner pageName='Contact'/>
        <section className="contact text-center text-dark section">
            <div className="container">
                <div className="row g-4">
                    {
                        contactDetail.map((item) => (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                <div className="card border address p-4 h-100">
                                    <div className="mb-4">
                                        <img src={path + item.imageurl} className="icons "/>
                                    </div>
                                    <h6 className="mb-4">{item.title}</h6>
                                    {item.text.map((txt) => (
                                        <a href={navLink(item.type, txt)} className="text-center m-0">
                                            {txt}
                                        </a>
                                    ))}
                                </div>
                            </div>))
                    }
                </div>
            </div>
        </section>
        <section className="loctaion section">
            <div className="container">
                <div className="row flex-md-row-reverse">
                    <div className="col-md-6 col-12">
                        <div className="shadow p-3 p-sm-5 p-md-6">
                            <div className="card-header  px-0 pt-0 ">
                                <h2 className="mb-3 fw-bold">Drop Us a Line</h2>
                            </div>
                            <form className="card-body px-0 pb-0 pt-4" onSubmit={(e) => handleSubmit(e)}>
                                <div className="input-floating-label form-floating mb-4">
                                    <input type="text" className="form-control bg-transparent"
                                           id="floatingName" placeholder="Password" required
                                           onChange={(e) => setFormData({...formData, fullName: e.target.value})}/>
                                    <label htmlFor="floatingName">Full name</label>
                                    <br/>
                                </div>
                                <div className="input-floating-label form-floating mb-4">
                                    <input type="email" className="form-control bg-transparent" id="floatingInput"
                                           placeholder="name@example.com" required
                                           onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                                    <label htmlFor="floatingInput">Email ID</label>
                                </div>
                                <div className="input-floating-label form-floating mb-4">
                                    <input type="tel" className="form-control bg-transparent" id="floatingNumber"
                                           placeholder="Phone" required value={formData.phone}
                                           onChange={(e) => {
                                               setFormData({...formData, phone: e.target.value})
                                           }}/>
                                    <label htmlFor="floatingNumber">Phone Number</label>
                                </div>
                                <div className="input-floating-label form-floating mb-4">
                                    <textarea className="form-control bg-transparent"
                                              placeholder="Leave a comment here"
                                              id="floatingTextarea2"
                                              style={{height: "100px"}} required
                                              onChange={(e) => setFormData({
                                                  ...formData, message: e.target.value
                                              })}></textarea>
                                    <label htmlFor="floatingTextarea2">Message</label>
                                </div>
                                <button className="action-button mt-2 mt-md-4">
                                    <span></span>
                                    <i>
                                        <input type='onSubmit' value='Send Message' className='submit-btn'
                                               disabled={isDisable}/>
                                    </i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <iframe className="w-100 h-100 grayscale d-block mt-8"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d875.3952082525467!2d77.16997157535548!3d28.642322394556224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d038bb473d13d%3A0x9a7105c1bb661dfa!2sModern%20Pythian%20Games!5e0!3m2!1sen!2sin!4v1717524008086!5m2!1sen!2sin"
                                allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default Contact